import React from 'react';
import './Spinner.css';


const Spinner = () => {
        return (

            <div className="loader">

            </div>
        )
}

export default Spinner;